// ** Redux, Thunk & Root Reducer Imports
import thunk from "redux-thunk";
import createDebounce from "redux-debounced";
import rootReducer from "../reducers/rootReducer";
import { createStore, applyMiddleware, compose } from "redux";

// ** init middleware
const middleware = [thunk, createDebounce()];

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// ** Create store
// const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(...middleware)))

const persistentState = localStorage.getItem("state")
  ? JSON.parse(localStorage.getItem("state"))
  : {};

const store = createStore(
  rootReducer,
  persistentState,
  composeEnhancers(applyMiddleware(...middleware))
);

store.subscribe(() => {
  const state = store.getState();
  const serializedState = JSON.stringify(state);
  localStorage.setItem("state", serializedState);
});

export { store };
