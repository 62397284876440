import { toast } from "react-hot-toast";
import moment from "moment";
import Avatar from "@components/avatar";
import { AlertCircle, CheckCircle, X, XCircle } from "react-feather";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = (obj) => Object.keys(obj).length === 0;

// ** Returns K format from a number
export const kFormatter = (num) =>
  num > 999 ? `${(num / 1000).toFixed(1)}k` : num;

// ** Converts HTML to string
export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, "");

// ** Checks if the passed date is today
const isToday = (date) => {
  const today = new Date();
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  );
};

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (
  value,
  formatting = { month: "short", day: "numeric", year: "numeric" }
) => {
  if (!value) return value;
  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value);
  let formatting = { month: "short", day: "numeric" };

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: "numeric", minute: "numeric" };
  }

  return new Intl.DateTimeFormat("en-US", formatting).format(new Date(value));
};

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => sessionStorage.getItem("userData");
export const getUserData = () => JSON.parse(sessionStorage.getItem("userData"));

export const userHasPermission = (roles) => {
  const user = getUserData();
  if (user && user.role && roles) {
    //return roles.some((role) => user.role.includes(role));
    return roles.includes(user.role);
  }
  return false;
};

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = (userRole) => {
  if (userRole === "admin") return "/";
  if (userRole === "client") return "/access-control";
  return "/login";
};

// ** React Select Theme Colors
export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#7367f01a", // for option hover bg-color
    primary: "#c40808", // for selected option bg-color
    neutral10: "#7367f0", // for tags bg-color
    neutral20: "#ededed", // for input border-color
    neutral30: "#ededed" // for input hover border-color
  }
});

export const getSelectData = (data, name = "name") => {
  let select = null;
  if (data) {
    select = {};
    if (data.hasOwnProperty("value")) {
      select.id = data.value;
    } else if (data.hasOwnProperty("id")) {
      select.id = data.id;
    }
    if (data.hasOwnProperty("label")) {
      select[name] = data.label;
    }
  }
  return select;
};

export const setSelectData = (data, name = "name") => {
  return { value: data.id, label: data[name] };
};

export const getMultiSelectData = (data, name = "name") => {
  return data.map((val) => {
    let select = { id: val.value };
    select[name] = val.label;
    return select;
  });
};

export const setMultiSelectData = (data, name = "name") => {
  return data.map((val) => {
    return { value: val.id, label: val[name] };
  });
};

export const successMassage = (title) => {
  toast.success(title);
};

export const errorMassage = (title) => {
  toast.error(title);
};

// ** Converts table to CSV
const convertArrayOfObjectsToCSV = (array) => {
  let result;

  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const keys = Object.keys(array[0]);

  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  array.forEach((item) => {
    let ctr = 0;
    keys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
};

// ** Downloads CSV
export const downloadCSV = (array) => {
  if (array && array.length > 0) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv === null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  } else {
    errorMassage("No records to export!");
  }
};

export const roleEnum = [
  {
    value: "role_admin",
    label: "Administrator"
  },
  {
    value: "role_user",
    label: "Korisnik"
  }
];

export const getRoleEnumData = (data) => {
  return data.value;
};

export const setRoleEnumData = (data) => {
  return roleEnum.find((val) => val.value === data);
};

export const textToUpperCase = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const setDate = (date) => {
  if (date) {
    return moment(date);
  }
  return undefined;
};

export const getDataFormat = (date, fomrt = "YYYY-MM-DD") => {
  if (date) {
    return moment(date).format(fomrt);
  }
  return undefined;
};

export const periodEnum = [
  {
    value: "monthly",
    label: "Mjesečna"
  },
  {
    value: "three_month",
    label: "3 Mjeseca"
  },
  {
    value: "six_month",
    label: "6 Mjeseca"
  },
  {
    value: "yearly",
    label: "Godišnja"
  }
];

export const getPeriodEnumData = (data) => {
  return data.value;
};

export const setPeriodEnumData = (data) => {
  return periodEnum.find((val) => val.value === data);
};

export const entryMessage = (
  title = "",
  description = "",
  status = "error",
  timer = 2000
) => {
  return toast(
    (t) => (
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          {status === "error" ? (
            <>
              <XCircle
                size={32}
                className="me-1"
                style={{ marginRight: "15px", color: "#ea5455" }}
              />
            </>
          ) : status === "warning" ? (
            <>
              <AlertCircle
                size={32}
                className="me-1"
                style={{ marginRight: "15px", color: "#ff9f43" }}
              />
            </>
          ) : (
            <>
              <CheckCircle
                size={32}
                className="me-1"
                style={{ marginRight: "15px", color: "#28c76f" }}
              />
            </>
          )}

          <div>
            <p className="mb-0">{title}</p>
            <small>{description}</small>
          </div>
        </div>
      </div>
    ),
    {
      style: {
        minWidth: "300px",
        borderRadius: "10px",
        minHeight: "80px",
        backgroundColor:
          status === "error"
            ? "#fadcdd"
            : status === "warning"
            ? "#ffebd9"
            : "#d4f3e2"
      }
    },
    {
      duration: timer
    }
  );
};

export const entrySuccessMessage = (data) => {
  return toast(
    (t) => (
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <CheckCircle
            size={32}
            className="me-1"
            style={{ marginRight: "15px", color: "#28c76f" }}
          />
          <div>
            <p className="mb-0">John Doe</p>
            <small>Prijava uspješna!</small>
          </div>
        </div>
        {/* <X size="14" onClick={() => toast.dismiss(t.id)} /> */}
      </div>
    ),
    {
      style: {
        minWidth: "300px",
        backgroundColor: "#d4f3e2"
      }
    }
  );
};

const MySwal = withReactContent(Swal);

export const sweetAlertModal = (
  title = "",
  description = "",
  status = "error",
  timer = 5000
) => {
  return MySwal.fire({
    title: title,
    text: description,
    icon: status,
    showConfirmButton: false,
    timer: timer
  });
};

export const entryErrorMessage = (data) => {
  return toast(
    (t) => (
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <XCircle
            size={32}
            className="me-1"
            style={{ marginRight: "15px", color: "#ea5455" }}
          />
          <div>
            <p className="mb-0">John Doe</p>
            <small>Neovlašteni pristup!</small>
          </div>
        </div>
        {/* <X size="14" onClick={() => toast.dismiss(t.id)} /> */}
      </div>
    ),
    {
      style: {
        minWidth: "300px",
        backgroundColor: "#fadcdd"
      }
    }
  );
};

export const entryWarrningMessage = (data) => {
  return toast(
    (t) => (
      <div className="w-100 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <AlertCircle
            size={32}
            className="me-1"
            style={{ marginRight: "15px", color: "#ff9f43" }}
          />
          <div>
            <p className="mb-0">{data}</p>
            <small>Član nije pronađen!</small>
          </div>
        </div>
        {/* <X size="14" onClick={() => toast.dismiss(t.id)} /> */}
      </div>
    ),
    {
      style: {
        minWidth: "300px",
        backgroundColor: "#ffebd9"
      }
    }
  );
};
