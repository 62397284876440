import { getUserData } from "@utils";
const user = getUserData();

// ** Initial State
const initialState = {
  cart: [],
  users: user
    ? [
        {
          value: user.id,
          label: `${user.firstname} ${user.lastname}`,
          percentage: "100",
          total: 0
        }
      ]
    : [],
  client: undefined,
  payment: undefined,
  subtotal: 0,
  disableDiscount: false,
  discount: 0,
  discountTotal: 0,
  tax: 7,
  taxTotal: 0,
  total: 0,
  discountType: "percentage",
  paymentData: ""
};

const ecommerceReducer = (state = initialState, action) => {
  let index = action.data
    ? state.cart.findIndex(
        (item) => item.id === action.data.id && item.type === action.data.type
      )
    : -1;
  switch (action.type) {
    case "SET_CLEAR_ALL":
      return { ...initialState, cart: [], users: [] };
    case "SET_INITIAL_STATE":
      return { ...initialState };
    case "DELETE_CART_ITEM":
      delete state.cart[index];
      state.cart = state.cart.filter((item) => item);

      state = calculateTotal(state);
      return { ...state };
    case "ADD_TO_CART":
      if (index >= 0) {
        state.cart[index].quantity += 1;
        state.cart[index] = calculatePrice(state.cart[index]);
      } else {
        state.cart.push({
          ...action.data,
          quantity: 1,
          discount: 0,
          discountType: "percentage",
          price: Number(action.data.sale_price)
        });
      }

      state = calculateTotal(state);
      return { ...state };
    case "SET_CART_ITEM_QUANTITY":
      if (index >= 0) {
        state.cart[index].quantity = Number(action.qty);
        state.cart[index] = calculatePrice(state.cart[index]);
      }

      state = calculateTotal(state);
      return { ...state };
    case "SET_CART_ITEM_DISCOUNT":
      if (index >= 0) {
        state.cart[index].discount = Number(action.discount);
        state.cart[index] = calculatePrice(state.cart[index]);
      }

      state = calculateTotal(state);
      return { ...state };
    case "SET_CART_ITEM_DISCOUNT_TYPE":
      if (index >= 0) {
        state.cart[index].discount = 0;
        state.cart[index].discountType = action.discountType;
        state.cart[index] = calculatePrice(state.cart[index]);
      }

      state = calculateTotal(state);
      return { ...state };
    case "SET_CART_DISCOUNT":
      state.discount = action.discount;

      state = calculateTotal(state);
      return { ...state };
    case "SET_CART_DISCOUNT_TYPE":
      state.discount = 0;
      state.discountType = action.discountType;

      state = calculateTotal(state);
      return { ...state };
    case "SET_CART_CLIENT":
      state.client = action.client;
      return { ...state };
    case "SET_CART_USERS":
      let users = action.users;
      if (users.length) {
        let percentage = 100.0 / users.length;
        if (percentage % 1 != 0) {
          percentage = percentage.toFixed(2);
        }
        users = users.map((user) => {
          return { ...user, percentage: percentage };
        });
      }
      state.users = users;

      state = calculateTotal(state);
      return { ...state };
    case "SET_CART_USERS_PERCENTSGE":
      let userIndex = state.users.findIndex((user) => user.value === action.id);

      if (userIndex >= 0) {
        let users = state.users;
        let percentage = action.percentage > 100 ? 100 : action.percentage;
        // if (users.length > 1) {
        //   let otherPercentage = 100 - percentage;
        //   if (otherPercentage <= 0) {
        //     otherPercentage = 0;
        //   }
        //   otherPercentage = otherPercentage / (users.length - 1);
        //   if (otherPercentage % 1 != 0) {
        //     otherPercentage = otherPercentage.toFixed(2);
        //   }
        //   users = users.map((user) => {
        //     return { ...user, percentage: Number(otherPercentage) };
        //   });
        // }
        users[userIndex].percentage = Number(percentage);

        state.users = users;
      }

      state = calculateTotal(state);
      return { ...state };
    case "SET_CART_PAYMENT":
      state.payment = action.payment;
      return { ...state };
    case "SET_CART_PAYMENT_DATA":
      state.paymentData = action.paymentData;
      return { ...state };
    default:
      return state;
  }
};

const calculatePrice = (item) => {
  item.price = Number(item.quantity) * Number(item.sale_price);
  if (item.discount && item.discount > 0) {
    let discount =
      item.discountType === "percentage"
        ? item.price * (item.discount / 100)
        : item.discount;
    item.price -= discount;
  }

  return item;
};

const calculateTotal = (state) => {
  let subTotalPrice = 0;
  let disableDiscount = false;
  state.cart.forEach((item) => {
    subTotalPrice += Number(item.price);
    if (item.discount && item.discount > 0) {
      disableDiscount = true;
    }
  });

  let totalPrice = subTotalPrice;
  let totalDiscountPrice = 0;
  if (!disableDiscount && state.discount && state.discount > 0) {
    let discount =
      state.discountType === "percentage"
        ? subTotalPrice * (state.discount / 100)
        : state.discount;
    totalDiscountPrice = discount * -1;
    totalPrice += totalDiscountPrice;
  } else {
    state.discount = 0;
    totalDiscountPrice = 0;
  }
  state.discountTotal = totalDiscountPrice;
  let taxTotal = totalPrice * (state.tax / 100);
  state.taxTotal = taxTotal;
  let subtotal = subTotalPrice - taxTotal;
  state.subtotal = subtotal;
  state.total = totalPrice;
  state.disableDiscount = disableDiscount;

  if (state.users.length > 0) {
    let users = state.users.map((user) => {
      let userTotal = subtotal * (user.percentage / 100);
      return { ...user, total: userTotal };
    });

    state.users = users;
  }

  return state;
};

export default ecommerceReducer;
